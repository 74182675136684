/* eslint-disable max-len */
import { merge } from "lodash";
import { frCommon } from "../../../common";
import { BaseLang } from "../BaseLang";

export const fr: BaseLang = merge(frCommon, {
    apps: {
        account: {
            name: "Compte",
            description: "Gérez vos informations personnelles et vos préférences."
        },
        timestamping: {
            name: "Timestamping",
            description: "Garantissez la date exacte et l'intégrité des données de vos documents importants."
        },
        sealing: {
            name: "Sealing",
            description: "Scellez vos documents et données pour garantir leur intégrité et leur authenticité."
        },
        archiving: {
            name: "Archiving",
            description: "Archivez vos données et documents de manière sécurisée et durable."
        }
    },
    register: {
        slogan: "La confiance réinventée"
    },
    onboarding: {
        title: "Bienvenue sur Evidency!",
        subtitle: "Spécialiste dans la gestion du cycle de vie de la preuve numérique."
    }
});
