/* eslint-disable max-len */
import { merge } from "lodash";
import { itCommon } from "../../../common";
import { BaseLang } from "../BaseLang";

export const it: BaseLang = merge(itCommon, {
    apps: {
        account: {
            name: "Account",
            description: "Gestisci il tuo account e la tua organizzazione."
        },
        timestamping: {
            name: "Timestamping",
            description: "Dimostrate la data esatta e l'integrità dei dati dei vostri documenti importanti." // to review
        },
        sealing: {
            name: "Sealing",
            description: "Mantenete la vostra proprietà intellettuale segreta e protetta." // to review
        },
        archiving: {
            name: "Archiving",
            description: "Archivia i tuoi documenti in modo sicuro e conforme." // to review
        }
    },
    register: {
        slogan: "Fiducia reinventata"
    },
    onboarding: {
        title: "Benvenuto a Evidency!",
        subtitle: "Specialista nella gestione del ciclo di vita della prova digitale."
    }
});
