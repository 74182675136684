/* eslint-disable max-len */
import { merge } from "lodash";
import { enCommon } from "../../../common";
import { BaseLang } from "../BaseLang";

export const en: BaseLang = merge(enCommon, {
    apps: {
        account: {
            name: "Account",
            description: "Manage your account and your organisation."
        },
        timestamping: {
            name: "Timestamping",
            description: "Prove the exact date and the data integrity of your important documents."
        },
        sealing: {
            name: "Sealing",
            description: "Seal your documents and data to ensure their integrity and authenticity."
        },
        archiving: {
            name: "Archiving",
            description: "Archive your data and documents securely and durably."
        }
    },
    register: {
        slogan: "Trust reinvented"
    },
    onboarding: {
        title: "Welcome to Evidency!",
        subtitle: "Specialist in the management of the digital proof lifecycle."
    }
});
