import { CommonApp, CommonAppProps } from "../../../../common";
import LogoSpinnerSvg from "../../../design-system/ressources/LogoSpinnerSvg";
import logoWhitePath from "../../../design-system/ressources/logo-white.svg";
import logoPath from "../../../design-system/ressources/logo.svg";
import { BaseLang } from "../../../lang";
import { APPS_INFOS, APP_INFO } from "../../helpers";

export default function BaseApp<TLang extends BaseLang, TAppItem extends { id: string }>(props: CommonAppProps<TLang, TAppItem>): JSX.Element {
    return (
        <CommonApp
            {...props}
            appsInfos={APPS_INFOS}
            appCode={APP_INFO.appCode}
            LogoSpinnerSvg={LogoSpinnerSvg}
            logoPath={logoPath}
            logoWhitePath={logoWhitePath}
        />
    );
}
