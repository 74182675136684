import { IconSvgProps } from "../../../../../common";

export default function SealingSvg({ size }: IconSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 164 153" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M61.9077 152.802C61.6197 152.802 61.4278 152.802 61.2358 152.706C60.5639 152.61 59.988 152.13 59.6041 151.554C40.312 117.961 21.1158 84.8471 0.383924 48.8543C0 48.2784 -0.0959809 47.5105 0.0959809 46.8387C0.287943 46.1668 0.671866 45.5909 1.34373 45.303C29.2742 29.0822 50.39 16.8926 79.0882 0.383924C79.6641 0 80.336 -0.0959809 81.0079 0.0959809C81.6797 0.287943 82.2556 0.767847 82.6395 1.34373L93.2934 19.7721C93.9653 21.0198 93.5814 22.5555 92.3336 23.3234H92.2376C99.9161 36.0888 123.239 85.423 123.335 85.519V85.7109L123.239 85.9029L81.9677 137.733C82.1596 137.829 82.3516 138.116 82.4476 138.308C83.2154 139.556 82.8315 141.188 81.5837 141.86L63.1554 152.514C62.7715 152.706 62.3876 152.802 61.9077 152.802Z"
                fill="#DEE1E4"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M123.335 142.244H33.4972C32.0575 142.244 30.9058 141.092 30.9058 139.652V21.0198C30.9058 19.5801 32.0575 18.4283 33.4972 18.4283H123.335C124.775 18.4283 125.927 19.5801 125.927 21.0198C125.927 62.7715 125.927 99.3402 125.927 139.652C125.927 141.092 124.775 142.244 123.335 142.244Z"
                fill="#F2F3F4"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M109.706 41.2718H47.1266C45.6869 41.2718 44.5352 40.024 44.5352 38.5843C44.5352 37.1446 45.6869 35.9928 47.1266 35.9928H109.706C111.146 35.9928 112.298 37.1446 112.298 38.5843C112.298 40.024 111.146 41.2718 109.706 41.2718Z"
                fill="#4A7398"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M109.706 57.8765H47.1266C45.6869 57.8765 44.5352 56.7247 44.5352 55.285C44.5352 53.8453 45.6869 52.6935 47.1266 52.6935H109.706C111.146 52.6935 112.298 53.8453 112.298 55.285C112.298 56.7247 111.146 57.8765 109.706 57.8765Z"
                fill="#4A7398"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M109.706 74.5771H47.1266C45.6869 74.5771 44.5352 73.4254 44.5352 71.9856C44.5352 70.5459 45.6869 69.3942 47.1266 69.3942H109.706C111.146 69.3942 112.298 70.5459 112.298 71.9856C112.298 73.4254 111.146 74.5771 109.706 74.5771Z"
                fill="#4A7398"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M109.706 91.2778H47.1266C45.6869 91.2778 44.5352 90.126 44.5352 88.6863C44.5352 87.2466 45.6869 86.0948 47.1266 86.0948H109.706C111.146 86.0948 112.298 87.2466 112.298 88.6863C112.298 90.126 111.146 91.2778 109.706 91.2778Z"
                fill="#4A7398"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M62.0996 133.509C60.3719 133.509 58.8362 132.742 57.7804 131.302L57.5885 131.014L57.2045 131.206C56.5327 131.494 55.7648 131.59 54.997 131.59C52.3095 131.59 50.1979 129.67 49.718 126.887V126.503H49.3341C47.6065 126.215 46.1667 125.255 45.3029 123.815C44.5351 122.376 44.4391 120.648 45.015 119.016L45.2069 118.728L44.919 118.44C43.5753 117.385 42.7114 115.849 42.7114 114.121C42.7114 112.49 43.5753 110.954 44.919 109.802L45.2069 109.61L45.015 109.322C44.4391 107.691 44.5351 105.963 45.3029 104.523C46.1667 103.083 47.6065 102.124 49.3341 101.836H49.718V101.452C50.1979 98.6683 52.3095 96.7487 54.997 96.7487C55.7648 96.7487 56.5327 96.8447 57.2045 97.1326L57.5885 97.3246L57.7804 97.0367C58.8362 95.5969 60.3719 94.8291 62.0996 94.8291C63.7312 94.8291 65.2669 95.5969 66.4187 97.0367L66.6107 97.3246L66.8986 97.1326C67.6665 96.8447 68.3383 96.7487 69.1062 96.7487C71.7936 96.7487 74.0012 98.6683 74.3851 101.452V101.836H74.769C76.4967 102.124 78.0324 103.083 78.8002 104.523C79.6641 105.963 79.76 107.691 79.0882 109.322L78.9922 109.61L79.1842 109.802C80.6239 110.954 81.3917 112.49 81.3917 114.121C81.3917 115.849 80.6239 117.385 79.1842 118.44L78.9922 118.728L79.0882 119.016C79.76 120.648 79.6641 122.376 78.8002 123.815C78.0324 125.255 76.4967 126.215 74.769 126.503H74.3851V126.887C74.0012 129.67 71.7936 131.59 69.1062 131.59C68.3383 131.59 67.6665 131.494 66.8986 131.206L66.6107 131.014L66.4187 131.302C65.2669 132.742 63.7312 133.509 62.0996 133.509Z"
                fill="#72ACE1"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M62.0996 123.815C56.7247 123.815 52.4055 119.496 52.4055 114.121C52.4055 108.842 56.7247 104.427 62.0996 104.427C67.3785 104.427 71.7937 108.842 71.7937 114.121C71.7937 119.496 67.3785 123.815 62.0996 123.815Z"
                fill="#4A7398"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M103.845 107H94.0423C92.4648 107 91 105.889 91 104.5C91 103.111 92.4648 102 94.0423 102H103.845C105.535 102 107 103.111 107 104.5C107 105.889 105.535 107 103.845 107Z"
                fill="#4A7398"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M108.65 126.119H93.1015C91.7578 126.119 90.51 124.967 90.51 123.527C90.51 122.088 91.7578 120.936 93.1015 120.936H108.65C110.09 120.936 111.242 122.088 111.242 123.527C111.242 124.967 110.09 126.119 108.65 126.119Z"
                fill="#4A7398"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M150.69 74.7691C151.842 75.441 153.377 75.057 154.049 73.9053L163.455 57.5885C164.127 56.4367 163.743 54.9011 162.592 54.2292L156.257 50.5819C155.105 49.91 153.665 50.294 152.994 51.4457C152.322 52.6935 152.706 54.1332 153.857 54.8051L158.081 57.2046L149.826 71.5057C149.154 72.6575 149.538 74.0972 150.69 74.7691Z"
                fill="#4A7398"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M115.273 115.945L124.967 109.226C125.255 109.034 125.543 108.746 125.639 108.458L125.831 108.074L112.969 100.684L112.777 100.972C112.585 101.26 112.489 101.644 112.489 102.028L111.818 109.802L118.536 113.641L115.273 115.945ZM127.846 104.619L155.969 55.9568L143.011 48.5663L114.985 97.1326L127.846 104.619Z"
                fill="#4A7398"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M155.969 55.9569L157.793 52.7895C160.384 48.3744 158.464 44.1512 155.297 42.3276C152.13 40.5039 147.523 40.8879 145.123 45.015L143.011 48.5663L155.969 55.9569Z"
                fill="#294966"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M124.967 109.226C125.255 109.034 125.543 108.746 125.639 108.458L125.831 108.074L112.969 100.684L112.777 100.972C112.585 101.26 112.489 101.644 112.489 102.028L111.818 109.802L118.536 113.641L124.967 109.226Z"
                fill="#DEE1E4"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M112.681 116.041C113.449 116.521 114.505 116.521 115.273 115.945L118.536 113.641L111.818 109.802L111.434 113.833C111.338 114.697 111.818 115.657 112.681 116.041Z"
                fill="#163046"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M125.831 108.074L127.847 104.619L114.985 97.1326L112.969 100.684L125.831 108.074Z"
                fill="#72ACE1"
            />
        </svg>
    );
}
