import { AppInfo, AppsInfos, PersonLogo, SHARED_URL } from "../../../common";
import { SealingLogo, TimestampingLogo } from "../../design-system/components/Logos/Logos";

export enum AppCode {
    TIMESTAMPING = "timestamping",
    // ARCHIVING = "archiving", // Temporary disabled as it's a WIP
    SEALING = "sealing",
    ACCOUNT = "account"
}

function getAppCode(): AppCode {
    const baseUrl = window.location.pathname;
    if (baseUrl.startsWith(SHARED_URL.app.timestamping)) {
        return AppCode.TIMESTAMPING;
    }
    if (baseUrl.startsWith(SHARED_URL.app.sealing)) {
        return AppCode.SEALING;
    }
    // if (baseUrl.startsWith(SHARED_URL.app.archiving)) {
    //     return AppCode.ARCHIVING;
    // }
    return AppCode.ACCOUNT;
}

export const APPS_INFOS: AppsInfos<AppCode> = {
    [AppCode.ACCOUNT]: {
        appCode: AppCode.ACCOUNT,
        baseUrl: SHARED_URL.app.index,
        logo: PersonLogo
    },
    [AppCode.TIMESTAMPING]: {
        appCode: AppCode.TIMESTAMPING,
        baseUrl: SHARED_URL.app.timestamping,
        logo: TimestampingLogo
    },
    [AppCode.SEALING]: {
        appCode: AppCode.SEALING,
        baseUrl: SHARED_URL.app.sealing,
        logo: SealingLogo
    }
    // [AppCode.ARCHIVING]: {
    //     appCode: AppCode.ARCHIVING,
    //     baseUrl: SHARED_URL.app.archiving,
    //     logo: TimestampingLogo // to be updated
    // }
};

export const APP_INFO: AppInfo<AppCode> = APPS_INFOS[getAppCode()];

export const BRAND_NAME = "evidency";
